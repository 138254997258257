export const arraydata = [
    {
        id: 1,
        sitename: "blue rock",
        category: "local",
        lat: 38.1384,
        lng: -122.1959,
        station: "STO",
        grid_x: 13,
        grid_y: 54,
        hourstart: 10,
        hourend: 20,
        speedmin_ideal: 8,
        speedmax_ideal: 13,
        speedmin_edge: 6,
        speedmax_edge: 16,
        lightwind_ok: "no",
        dir_ideal: ["SW", "WSW", "W", "WNW"],
        dir_edge: ["SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.1384&lon=-122.1959&wfo=STO&zcode=CAZ017&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.1959&lat=38.1384#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.1384&lon=-122.1959&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.138/-122.196/wind?37.539,-122.196,8,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KVCB&state=CA" },
        ],
        link_current: [
            { id: 1, description: "KCAVALLE53*", url: "https://www.wunderground.com/personal-weather-station/dashboard?ID=KCAVALLE53" },
            { id: 2, description: "Bridge Wind", url: "https://tidesandcurrents.noaa.gov/met.html?id=9415118" },
            { id: 3, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.1384&lon=-122.1959&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.1384,-122.1959" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/13,54/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.1384,-122.1959" },
            { id: 4, description: "Site Guide", url: "https://www.penguinparagliding.com/bluerock.html" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 2,
        sitename: "channing west",
        category: "local",
        lat: 38.0979,
        lng: -122.1816,
        station: "STO",
        grid_x: 13,
        grid_y: 52,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 8,
        speedmax_ideal: 12,
        speedmin_edge: 6,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["WSW", "W", "WNW"],
        dir_edge: ["SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.0979&lon=-122.1816&wfo=STO&zcode=CAZ017&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.1816&lat=38.0979#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.0979&lon=-122.1816&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.0979/-122.1816/wind?38.0979,-122.1816,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KVCB&state=CA" },
        ],
        link_current: [
            { id: 1, description: "KCABENIC65", url: "https://www.wunderground.com/dashboard/pws/KCABENIC65" },
            { id: 2, description: "Bridge Wind", url: "https://tidesandcurrents.noaa.gov/met.html?id=9415118" },
            { id: 3, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.0979&lon=-122.1816&zoom=13" },
            { id: 4, description: "Live webcam backside", url: "https://www.youtube.com/watch?v=JFkxBYPwkfY" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.0979,-122.1816" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/13,52/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.0979,-122.1816" },
            { id: 4, description: "Site Guide", url: "https://www.penguinparagliding.com/channingwest.html" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 3,
        sitename: "channing east",
        category: "local",
        lat: 38.1016,
        lng: -122.1811,
        station: "STO",
        grid_x: 13,
        grid_y: 52,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 5,
        speedmax_ideal: 9,
        speedmin_edge: 3,
        speedmax_edge: 13,
        lightwind_ok: "no",
        dir_ideal: ["ENE", "E", "ESE"],
        dir_edge: ["NE", "ENE", "E", "ESE", "SE"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.1384&lon=-122.1811&wfo=STO&zcode=CAZ018&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=11001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.1811&lat=38.1384#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.1384&lon=-122.1811&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.1384/-122.1811/wind?38.1384,-122.1811,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KVCB&state=CA" },
        ],
        link_current: [
            { id: 1, description: "KCABENIC65*", url: "https://www.wunderground.com/dashboard/pws/KCABENIC65" },
            { id: 2, description: "Bridge Wind", url: "https://tidesandcurrents.noaa.gov/met.html?id=9415118" },
            { id: 3, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.1016&lon=-122.1811&zoom=13" },
            { id: 4, description: "Live webcam backside", url: "https://www.youtube.com/watch?v=JFkxBYPwkfY" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.1016,-122.1811" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/13,52/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.1016,-122.1811" },
            { id: 4, description: "Site Guide", url: "https://www.penguinparagliding.com/channingeast.html" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 4,
        sitename: "diablo juniper",
        category: "local",
        lat: 37.8778,
        lng: -121.9351,
        station: "MTR",
        grid_x: 102,
        grid_y: 106,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 0,
        speedmax_ideal: 8,
        speedmin_edge: 0,
        speedmax_edge: 14,
        lightwind_ok: "yes",
        dir_ideal: ["SSW", "SW", "WSW"],
        dir_edge: ["S", "SW", "WSW", "W"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.8778&lon=-121.9351&wfo=MTR&zcode=CAZ511&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.9351&lat=37.8778#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.8778&lon=-121.9351&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.8778/-121.9351/wind?37.8778,-121.9351,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KCCR&state=CA" },
            { id: 7, description: "Atmo", url: "https://sf.atmo.ai/wind@37.88387,-121.91553,11.83,20,0,1667778004" },
            { id: 8, description: "Winds Aloft", url: "http://www.usairnet.com/cgi-bin/Winds/Aloft.cgi?icao=SFO&hr=06" },
        ],
        link_current: [
            { id: 1, description: "6hr Current Wind", url: "http://bit.ly/2K2lyL3" },
            { id: 2, description: "Webcam Top", url: "http://www.alertwildfire.org/southeastbay/index.html?camera=Axis-Diablo" },
            { id: 3, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.8778&lon=-121.9351&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.8778,-121.9351" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/103,106/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.8778,-121.9351" },
            { id: 4, description: "Site Guide", url: "https://wingsofrogallo.org/mt-diablo/" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 5,
        sitename: "diablo towers",
        category: "local",
        lat: 37.882,
        lng: -121.919,
        station: "MTR",
        grid_x: 103,
        grid_y: 106,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 5,
        speedmax_ideal: 9,
        speedmin_edge: 3,
        speedmax_edge: 14,
        lightwind_ok: "yes",
        dir_ideal: ["WNW", "NW", "NNW"],
        dir_edge: ["W", "WNW", "W", "NW", "NNW", "N"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.8820&lon=-121.9190&wfo=MTR&zcode=CAZ511&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.9190&lat=37.8820#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.8820&lon=-121.9190&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.8820/-121.9190/wind?37.8820,-121.9190,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KCCR&state=CA" },
            { id: 7, description: "Atmo", url: "https://sf.atmo.ai/wind@37.88387,-121.91553,11.83,20,0,1667778004" },
            { id: 8, description: "Winds Aloft", url: "http://www.usairnet.com/cgi-bin/Winds/Aloft.cgi?icao=SFO&hr=06" },
        ],
        link_current: [
            { id: 1, description: "6hr Current Wind", url: "http://bit.ly/2K2lyL3" },
            { id: 2, description: "Webcam Top", url: "http://www.alertwildfire.org/southeastbay/index.html?camera=Axis-Diablo" },
            { id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.8820&lon=-121.9190&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.8820,-121.9190" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/103,106/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.8820,-121.9190" },
            { id: 4, description: "Site Guide", url: "https://wingsofrogallo.org/mt-diablo/" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 6,
        sitename: "ed levin",
        category: "local",
        lat: 37.4756,
        lng: -121.8614,
        station: "MTR",
        grid_x: 101,
        grid_y: 88,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 0,
        speedmax_ideal: 10,
        speedmin_edge: 0,
        speedmax_edge: 16,
        lightwind_ok: "yes",
        dir_ideal: ["SSE", "S", "SSW", "SW", "WSW", "W", "WNW"],
        dir_edge: ["SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.4756&lon=-121.8614&wfo=MTR&zcode=CAZ511&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.8614&lat=37.4756#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.4756&lon=-121.8614&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.4756/-121.8614/wind?37.4756,-121.8614,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KHWD&state=CA" },
            { id: 7, description: "Atmo", url: "https://sf.atmo.ai/wind@37.47475,-121.85950,12.24,20,0,1667778004" },
        ],
        link_current: [
            { id: 1, description: "WindSlammer", url: "http://windslammer.hang-gliding.com/WindSlammer/" },
            { id: 2, description: "Mt Alison", url: "https://mesowest.utah.edu/cgi-bin/droman/meso_graph_mesowest.cgi?stn1=PG674&stn2=PG674&unit=0&timeout=0000&time=LOCAL&hour1=00&day1=00&month1=&year1=&hours=12&past=0&graph1=TTD&graph2=WND&g1min=&g1max=&g2min=&g2max=&gsize=1&g1auto=1&g2auto=1&linetype=colorline" },
            { id: 3, description: "Skew-T", url: "http://topaflyers.com/weather/soundings/oak.png" },
            { id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.4756&lon=-121.8614&zoom=13" },
            { id: 5, description: "Webcam 2128", url: "https://ops.alertcalifornia.org/cam-console/2128" },
            { id: 6, description: "Webcam 2147", url: "https://ops.alertcalifornia.org/cam-console/2147" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.4756,-121.8614" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/101,88/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.4756,-121.8614" },
            { id: 4, description: "Site Guide", url: "https://wingsofrogallo.org/ed-levin/" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 7,
        sitename: "mission",
        category: "local",
        lat: 37.5177,
        lng: -121.892,
        station: "MTR",
        grid_x: 101,
        grid_y: 90,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 3,
        speedmax_ideal: 11,
        speedmin_edge: 0,
        speedmax_edge: 16,
        lightwind_ok: "yes",
        dir_ideal: ["SW", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["SSW", "SW", "WSW", "W", "WNW", "NW", "NNW", "N"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.5177&lon=-121.8920&wfo=MTR&zcode=CAZ511&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.8920&lat=37.5177#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.5177&lon=-121.8920&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.5177/-121.8920/wind?37.5177,-121.8920,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KHWD&state=CA" },
            { id: 7, description: "Atmo", url: "https://sf.atmo.ai/wind@37.5177,-121.8920,13.34,20,0,1667778004" },
        ],
        link_current: [
            { id: 1, description: "WindSlammer", url: "http://windslammer.hang-gliding.com/WindSlammer/" },
            { id: 2, description: "Mt Alison", url: "https://mesowest.utah.edu/cgi-bin/droman/meso_graph_mesowest.cgi?stn1=PG674&stn2=PG674&unit=0&timeout=0000&time=LOCAL&hour1=00&day1=00&month1=&year1=&hours=12&past=0&graph1=TTD&graph2=WND&g1min=&g1max=&g2min=&g2max=&gsize=1&g1auto=1&g2auto=1&linetype=colorline" },
            { id: 3, description: "Skew-T", url: "http://topaflyers.com/weather/soundings/oak.png" },
            { id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.5177&lon=-121.8920&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.5177,-121.8920" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/101,90/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.5177,-121.8920" },
            { id: 4, description: "Site Guide", url: "https://wingsofrogallo.org/ed-levin/" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 8,
        sitename: "marina, sand city",
        category: "local",
        lat: 36.6253,
        lng: -121.8434,
        station: "MTR",
        grid_x: 95,
        grid_y: 51,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["W", "WNW", "NW"],
        dir_edge: ["SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=36.6253&lon=-121.8434&wfo=MTR&zcode=CAZ530&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.8434&lat=36.6253#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=36.6253&lon=-121.8434&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/36.6253/-121.8434/wind?36.6253,-121.8434,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KMRY&state=CA" },
            { id: 7, description: "Tides", url: "https://tidesandcurrents.noaa.gov/noaatidepredictions.html?id=9413450" },
        ],
        link_current: [
            { id: 1, description: "Seaside HS Wind", url: "https://www.wunderground.com/dashboard/pws/KCASEASI29" },
            { id: 2, description: "Local WU Stations", url: "https://www.wunderground.com/wundermap?lat=36.636&lon=-121.8434&zoom=12" },
            { id: 3, description: "Webcam", url: "https://www.thesanctuarybeachresort.com/resort/beach-camera" },
            { id: 4, description: "Fog Satellite", url: "https://www.fog.today" },
            { id: 5, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=36.6253&lon=-121.8434&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/36.6253,-121.8434" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/95,51/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/36.6253,-121.8434" },
            { id: 4, description: "Site Guide", url: "http://www.coastalcondors.org" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 9,
        sitename: "mussel rock",
        category: "local",
        lat: 37.6698,
        lng: -122.4944,
        station: "MTR",
        grid_x: 81,
        grid_y: 101,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 15,
        speedmin_edge: 8,
        speedmax_edge: 20,
        lightwind_ok: "no",
        dir_ideal: ["W", "WNW", "NW"],
        dir_edge: ["SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.6698&lon=-122.4944&wfo=MTR&zcode=CAZ509&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.4944&lat=37.6698#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.6698&lon=-122.4944&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.6698/-122.4944/wind?37.6698,-122.4944,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://usairnet.com/cgi-bin/launch/code.cgi?sta=KSFO&model=avn&state=CA&Submit=Change+Location" },
            { id: 7, description: "Tides", url: "https://tidesandcurrents.noaa.gov/noaatidepredictions.html?id=9414290" },
        ],
        link_current: [
            { id: 1, description: "Musselrockwx", url: "https://musselrockwx.com" },
            { id: 2, description: "SailFlow", url: "https://www.sailflow.com/map#37.6698,-122.4944,12,1" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.6698,-122.4944" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/81,101/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.6698,-122.4944" },
            { id: 4, description: "Site Guide", url: "https://www.paragliding-lessons.com/mrsg/" },
            { id: 5, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 10,
        sitename: "tam",
        category: "local",
        lat: 37.9108,
        lng: -122.625,
        station: "MTR",
        grid_x: 79,
        grid_y: 113,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 2,
        speedmax_ideal: 8,
        speedmin_edge: 0,
        speedmax_edge: 12,
        lightwind_ok: "yes",
        dir_ideal: ["SE", "S", "SSW", "SW", "WSW", "W"],
        dir_edge: ["ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.91084&lon=-122.6250&wfo=MTR&zcode=CAZ507&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10101110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.6250&lat=37.9108#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.9108&lon=-122.6250&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.9108/-122.6250/wind?37.9108,-122.6250,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KSFO&state=CA" },
            { id: 7, description: "Atmo", url: "https://sf.atmo.ai/wind@37.9108,-122.6250,13.06,20,0,1667773089" },
            { id: 8, description: "Tides", url: "http://tides.mobilegeographics.com/locations/5546.html" },
        ],
        link_current: [
            { id: 1, description: "Wind Middle Peak", url: "https://www.wrh.noaa.gov/mesowest/getobext.php?sid=MDEC1&table=1&banner=off" },
            { id: 2, description: "Wind Muir Beach", url: "https://www.wunderground.com/dashboard/pws/KCAMUIRB7" },
            { id: 3, description: "Webcam Tam", url: "http://www.rntl.net/mttamcam.htm" },
            { id: 4, description: "Webcam Berkeley", url: "http://www.lawrencehallofscience.org/static/scienceview/scienceview.berkeley.edu/html/view/index.php" },
            { id: 5, description: "Fog Satellite", url: "https://www.fog.today" },
            { id: 6, description: "Fog GEOS West", url: "https://www.star.nesdis.noaa.gov/goes/sector_band.php?sat=G18&sector=pnw&band=GEOCOLOR&length=48&dim=1" },
            { id: 7, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.9108&lon=-122.6250&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.9108,-122.6250" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/79,113/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.9108,-122.6250" },
            { id: 4, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 11,
        sitename: "vaca ridge",
        category: "local",
        lat: 38.4003,
        lng: -122.1061,
        station: "MTR",
        grid_x: 101,
        grid_y: 131,
        hourstart: 11,
        hourend: 18,
        speedmin_ideal: 5,
        speedmax_ideal: 10,
        speedmin_edge: 0,
        speedmax_edge: 16,
        lightwind_ok: "yes",
        dir_ideal: ["SW", "WSW", "W"],
        dir_edge: ["SSW", "SW", "WSW", "W", "WNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.4003&lon=-122.1061&wfo=MTR&zcode=CAZ507&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.1061&lat=38.4003#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.4003&lon=-122.1061&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.4003/-122.1061/wind?38.4003/-122.1061,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KFAT&state=CA" },
        ],
        link_current: [
            { id: 1, description: "Wind Guru", url: "https://www.windguru.cz/station/2330" },
            { id: 2, description: "Weather Underground", url: "https://www.wunderground.com/dashboard/pws/KCANAPA198" },
            { id: 3, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.4003&lon=-122.1061&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.4003,-122.1061" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/101,131/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.4003,-122.1061" },
            { id: 4, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 12,
        sitename: "bidwell chico",
        category: "remote",
        lat: 39.7802,
        lng: -121.761,
        station: "STO",
        grid_x: 42,
        grid_y: 123,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 2,
        speedmax_ideal: 8,
        speedmin_edge: 0,
        speedmax_edge: 12,
        lightwind_ok: "no",
        dir_ideal: ["SSE", "SE", "ESE"],
        dir_edge: ["S", "SSE", "SE", "ESE", "E"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=39.7802&lon=-121.761&wfo=STO&zcode=CAZ016&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001000000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.761&lat=39.7802#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=39.7802&lon=-121.761&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/39.7802/-121.761/wind?39.7802,-121.761,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KCIC&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=39.7802&lon=-121.761&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/39.7802,-121.761" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/42,123/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/39.7802,-121.761" },
        ],
    },
    {
        id: 13,
        sitename: "big sur",
        category: "remote",
        lat: 35.971,
        lng: -121.453,
        station: "MTR",
        grid_x: 103,
        grid_y: 19,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 2,
        speedmax_ideal: 8,
        speedmin_edge: 0,
        speedmax_edge: 12,
        lightwind_ok: "yes",
        dir_ideal: ["SW", "WSW", "W"],
        dir_edge: ["SSW", "SW", "WSW", "W", "WNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=35.971&lon=-121.453&wfo=MTR&zcode=CAZ517&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.453&lat=35.971#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=35.971&lon=-121.453&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/39.7802/-121.761/wind?39.7802,-121.761,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KFAT&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=35.971&lon=-121.453&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/35.971,-121.453" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/103,19/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/35.971,-121.453" },
        ],
    },
    {
        id: 14,
        sitename: "drakes pt reyes",
        category: "remote",
        lat: 38.0265,
        lng: -122.9634,
        station: "MTR",
        grid_x: 68,
        grid_y: 120,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 8,
        speedmax_ideal: 12,
        speedmin_edge: 7,
        speedmax_edge: 20,
        lightwind_ok: "no",
        dir_ideal: ["SE", "SSE"],
        dir_edge: ["ESE", "SE", "SSE", "S"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.0265&lon=-122.9634&wfo=MTR&zcode=CAZ507&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.9634&lat=38.0265#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.0265&lon=-122.9634&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.0265/-122.9634/wind?38.0265,-122.9634,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KSFO&state=CA" },
            { id: 6, description: "Fog Satellite", url: "http://fog.today" },
        ],
        link_current: [
            { id: 1, description: "Park Closure Status", url: "https://www.nps.gov/pore/planyourvisit/conditions.htm" },
            { id: 2, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.0265&lon=-122.9634&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.0265,-122.9634" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/68,120/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.0265,-122.9634" },
        ],
    },
    {
        id: 15,
        sitename: "dunlap",
        category: "remote",
        lat: 36.765,
        lng: -119.098,
        station: "HNX",
        grid_x: 77,
        grid_y: 96,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 0,
        speedmax_ideal: 9,
        speedmin_edge: 0,
        speedmax_edge: 13,
        lightwind_ok: "yes",
        dir_ideal: ["WSW", "W", "WNW"],
        dir_edge: ["SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=36.765&lon=-119.098&wfo=HNX&zcode=CAZ096&gset=15&gdiff=5&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-119.098&lat=36.765#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=36.765&lon=-119.098&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/36.765/-119.098/wind?36.765,-119.098,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KFAT&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=36.765&lon=-119.098&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/36.765,-119.098" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/HNX/77,96/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/36.765,-119.098" },
            { id: 4, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 16,
        sitename: "duck, mcclellan",
        category: "remote",
        lat: 39.2295,
        lng: -119.7651,
        station: "REV",
        grid_x: 44,
        grid_y: 93,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 6,
        speedmax_ideal: 12,
        speedmin_edge: 0,
        speedmax_edge: 18,
        lightwind_ok: "yes",
        dir_ideal: ["WNW", "NW", "NNW", "N"],
        dir_edge: ["W", "WNW", "NW", "NNW", "N", "NNE"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=39.2295&lon=-119.7651&wfo=REV&zcode=NVZ002&gset=18&gdiff=8&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-119.7651&lat=39.2295#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=39.2295&lon=-119.7651&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=REV&issuedby=REV&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/39.2295/-119.7651/wind?39.2295,-119.7651,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KRNO&state=NV" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=39.2295&lon=-119.7651&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/39.2295,-119.7651" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/REV/44,93/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/39.2295,-119.7651" },
        ],
    },
    {
        id: 17,
        sitename: "elk",
        category: "remote",
        lat: 39.277,
        lng: -122.941,
        station: "EKA",
        grid_x: 89,
        grid_y: 31,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 0,
        speedmax_ideal: 8,
        speedmin_edge: 0,
        speedmax_edge: 11,
        lightwind_ok: "yes",
        dir_ideal: ["ESE", "SE", "SSE", "NW", "WNW"],
        dir_edge: ["ESE", "SE", "SSE", "S", "SSW", "SW", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=39.277&lon=-122.941&wfo=EKA&zcode=CAZ114&gset=18&gdiff=5&unit=0&tinfo=PY8&ahour=0&pcmd=11101110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.941&lat=39.277#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=39.277&lon=-122.941&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/39.277/-122.941/wind?39.277,-122.941,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=39.277&lon=-122.941&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/39.277,-122.941" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/EKA/89,31/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/39.277,-122.941" },
        ],
    },
    {
        id: 18,
        sitename: "hat creek",
        category: "remote",
        lat: 40.842,
        lng: -121.428,
        station: "STO",
        grid_x: 63,
        grid_y: 168,
        hourstart: 15,
        hourend: 18,
        speedmin_ideal: 5,
        speedmax_ideal: 9,
        speedmin_edge: 3,
        speedmax_edge: 15,
        lightwind_ok: "no",
        dir_ideal: ["WSW", "W", "WNW"],
        dir_edge: ["SW", "WSW", "W", "WNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=40.842&lon=-121.428&wfo=STO&zcode=CAZ014&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=00001110100000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.428&lat=40.842#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=40.842&lon=-121.428&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/40.842/-121.428/wind?40.842,-121.428,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KRBL&state=CA" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=40.842&lon=-121.428&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/40.842,-121.428" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/63,168/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/40.842,-121.428" },
        ],
    },
    {
        id: 19,
        sitename: "hull",
        category: "remote",
        lat: 39.509,
        lng: -122.937,
        station: "EKA",
        grid_x: 92,
        grid_y: 41,
        hourstart: 11,
        hourend: 18,
        speedmin_ideal: 2,
        speedmax_ideal: 6,
        speedmin_edge: 0,
        speedmax_edge: 10,
        lightwind_ok: "yes",
        dir_ideal: ["SSW", "SW", "WSW"],
        dir_edge: ["S", "SSW", "SW", "WSW", "W"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=39.509&lon=-122.937&wfo=EKA&zcode=CAZ114&gset=18&gdiff=5&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.937&lat=39.509#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=39.509&lon=-122.937&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/40.842/-121.428/wind?40.842,-121.428,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=39.509&lon=-122.937&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/39.509,-122.937" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/EKA/92,41/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/39.509,-122.937" },
        ],
    },
    {
        id: 20,
        sitename: "indian valley",
        category: "remote",
        lat: 40.194,
        lng: -120.923,
        station: "REV",
        grid_x: 13,
        grid_y: 143,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 3,
        speedmax_ideal: 7,
        speedmin_edge: 0,
        speedmax_edge: 9,
        lightwind_ok: "yes",
        dir_ideal: ["SSW", "SW", "WSW"],
        dir_edge: ["S", "SSW", "SW", "WSW", "W"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=40.1666&lon=-120.8695&wfo=REV&zcode=CAZ071&gset=18&gdiff=8&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-120.923&lat=40.194#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=40.194&lon=-120.923&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/40.194/-120.923/wind?40.194,-121.923,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=40.194&lon=-120.923&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/40.194,-120.923" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/REV/13,143/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/40.194,-120.923" },
        ],
    },
    {
        id: 21,
        sitename: "jenner goat rock",
        category: "remote",
        lat: 38.4421,
        lng: -123.1245,
        station: "MTR",
        grid_x: 66,
        grid_y: 140,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 14,
        speedmin_edge: 7,
        speedmax_edge: 20,
        lightwind_ok: "no",
        dir_ideal: ["WSW", "W", "WNW", "NW"],
        dir_edge: ["SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.4421&lon=-123.1245&wfo=MTR&zcode=CAZ505&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-123.1245&lat=38.4421#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.4421&lon=-123.1245&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.4421/-123.1245/wind?38.4421,-123.1245,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
        ],
        link_current: [
            { id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.4421&lon=-123.1245&zoom=13" },
            { id: 2, description: "Bodega Wind", url: "https://boon.ucdavis.edu/obs/shoreline/wind" },
            { id: 3, description: "Fog Satellite", url: "http://fog.today" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.4421,-123.1245" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/66,140/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.4421,-123.1245" },
            { id: 4, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 22,
        sitename: "jenner grade",
        category: "remote",
        lat: 38.478,
        lng: -123.163,
        station: "MTR",
        grid_x: 65,
        grid_y: 142,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 7,
        speedmax_ideal: 12,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["SSW", "SW", "WSW"],
        dir_edge: ["SSE", "S", "SSW", "SW", "WSW", "W"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.478&lon=-123.163&wfo=MTR&zcode=CAZ505&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-123.163&lat=38.478#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.478&lon=-123.163&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.478/-123.163/wind?38.478,-123.163,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
        ],
        link_current: [
            { id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.478&lon=-123.163&zoom=13" },
            { id: 2, description: "Bodega Wind", url: "https://boon.ucdavis.edu/obs/shoreline/wind" },
            { id: 3, description: "Fog Satellite", url: "http://fog.today" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.478,-123.163" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/65,142/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.478,-123.163" },
        ],
    },
    {
        id: 23,
        sitename: "lagoon valley",
        category: "remote",
        lat: 38.333,
        lng: -122.002,
        station: "STO",
        grid_x: 21,
        grid_y: 61,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 6,
        speedmax_ideal: 11,
        speedmin_edge: 1,
        speedmax_edge: 14,
        lightwind_ok: "no",
        dir_ideal: ["SW", "WSW", "W", "WNW"],
        dir_edge: ["SSW", "SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.333&lon=-122.002&wfo=STO&zcode=CAZ017&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001111000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.002&lat=38.333#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.333&lon=-122.002&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.333/-122.002/wind?38.333,-122.002,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 5, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KMHS&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.333&lon=-122.002&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.333,-122.002" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/21,61/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.333,-122.002" },
        ],
    },
    {
        id: 24,
        sitename: "oroville",
        category: "remote",
        lat: 39.537,
        lng: -121.628,
        station: "STO",
        grid_x: 45,
        grid_y: 112,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 7,
        speedmax_ideal: 10,
        speedmin_edge: 6,
        speedmax_edge: 12,
        lightwind_ok: "no",
        dir_ideal: ["N", "NNW"],
        dir_edge: ["N", "NNE", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=39.537&lon=-121.628&wfo=STO&zcode=CAZ016&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.628&lat=39.537#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=39.537&lon=-121.628&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/39.537/-121.628/wind?39.537,-121.628,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 5, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KCIC&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=39.537&lon=-121.628&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/39.537,-121.628" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/44,111/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/39.537,-121.628" },
        ],
    },
    {
        id: 25,
        sitename: "poplar",
        category: "remote",
        lat: 37.4554,
        lng: -122.4447,
        station: "MTR",
        grid_x: 81,
        grid_y: 91,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 8,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["WSW", "W", "WNW"],
        dir_edge: ["SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.4554&lon=-122.4447&wfo=MTR&zcode=CAZ507&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.4447&lat=37.4554#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.4554&lon=-122.4447&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.4554/-122.4447/wind?37.4554,-122.4447,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 5, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KHAF&state=CA" },
        ],
        link_current: [
            { id: 1, description: "Webcam", url: "http://samcam.samschowderhouse.com:8334/view/viewer_index.shtml?id=921" },
            { id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.4554&lon=-122.4447&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.4554,-122.4447" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/81,91/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.4554,-122.4447" },
        ],
    },
    {
        id: 26,
        sitename: "potato hill",
        category: "remote",
        lat: 39.3317,
        lng: -122.685,
        station: "STO",
        grid_x: 7,
        grid_y: 110,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 0,
        speedmax_ideal: 5,
        speedmin_edge: 0,
        speedmax_edge: 10,
        lightwind_ok: "yes",
        dir_ideal: ["ENE", "E", "ESE", "SE"],
        dir_edge: ["NE", "ENE", "E", "ESE", "SE", "S"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=39.317&lon=-122.685&wfo=STO&zcode=CAZ063&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lat=39.317&lon=-122.685&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=39.317&lon=-122.685&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/39.317/-122.685/wind?39.317,-122.685,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 5, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KRBL&state=CA" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.3317&lon=-122.685&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/39.317,-122.685" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/7,109/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/39.317,-122.685" },
            { id: 4, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 27,
        sitename: "sagebrush, cronin",
        category: "remote",
        lat: 38.822,
        lng: -120.889,
        station: "STO",
        grid_x: 64,
        grid_y: 75,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 0,
        speedmax_ideal: 10,
        speedmin_edge: 0,
        speedmax_edge: 12,
        lightwind_ok: "yes",
        dir_ideal: ["WSW", "W", "WNW"],
        dir_edge: ["S", "SW", "WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.822&lon=-120.889&wfo=STO&zcode=CAZ067&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-120.889&lat=38.822#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.822&lon=-120.889&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.822/-120.889/wind?38.822,-120.889,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 5, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KSAC&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.822&lon=-120.889&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.822,-120.889" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/11,51/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.822,-120.889" },
        ],
    },
    {
        id: 28,
        sitename: "sb vallejo",
        category: "remote",
        lat: 38.0772,
        lng: -122.2398,
        station: "STO",
        grid_x: 11,
        grid_y: 51,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["WSW", "W"],
        dir_edge: ["SW", "WSW", "W", "WNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.0772&lon=-122.2398&wfo=STO&zcode=CAZ018&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=48&pcmd=00001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.2398&lat=38.0772#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.0772&lon=-122.2398&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.0772/-122.2398/wind?38.0772,-122.2398,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 5, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KSAC&state=CA" },
        ],
        link_current: [{ id: 1, description: "Local Station", url: "https://www.wunderground.com/dashboard/pws/KCAVALLE1156" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.0772,-122.2398" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/11,51/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.0772,-122.2398" },
        ],
    },
    {
        id: 29,
        sitename: "slide mountain",
        category: "remote",
        lat: 39.319,
        lng: -119.867,
        station: "REV",
        grid_x: 41,
        grid_y: 97,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 2,
        speedmax_ideal: 6,
        speedmin_edge: 0,
        speedmax_edge: 10,
        lightwind_ok: "yes",
        dir_ideal: ["ENE", "E", "ESE"],
        dir_edge: ["NE", "ENE", "E", "ESE", "SE", "S"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=39.319&lon=-119.867&wfo=REV&zcode=NVZ002&gset=18&gdiff=8&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-119.867&lat=39.319#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=39.319&lon=-119.867&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=REV&issuedby=REV&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/39.319/-119.867/wind?39.319,-119.867,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KRNO&state=NV" },
            { id: 7, description: "Jet Stream", url: "http://virga.sfsu.edu/scripts/jetstream_model_fcst.html" },
            { id: 8, description: "Winds Aloft", url: "http://www.usairnet.com/cgi-bin/Winds/Aloft.cgi?location=RNO&Submit=Get+Forecast&hour=12&course=azimuth" },
        ],
        link_current: [
            { id: 1, description: "Wind Slide Peak", url: "https://wrcc.dri.edu/weather/slide.html" },
            { id: 2, description: "Wind Peavine", url: "https://www.wrh.noaa.gov/rev/remotedata/newpvpn2.php" },
            { id: 3, description: "Webcams", url: "https://skirose.com/the-mountain-web-cams/" },
            { id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=39.319&lon=-119.867&zoom=13" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/39.319,-119.867" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/REV/41,97/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/39.319,-119.867" },
        ],
    },
    {
        id: 30,
        sitename: "st helena",
        category: "remote",
        lat: 38.667,
        lng: -122.628,
        station: "MTR",
        grid_x: 86,
        grid_y: 146,
        hourstart: 11,
        hourend: 18,
        speedmin_ideal: 2,
        speedmax_ideal: 10,
        speedmin_edge: 4,
        speedmax_edge: 15,
        lightwind_ok: "yes",
        dir_ideal: ["SW", "WSW"],
        dir_edge: ["S", "SSW", "SW", "WSW", "W"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.667&lon=-122.628&wfo=MTR&zcode=CAZ507&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.628&lat=38.667#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.667&lon=-122.628&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.667/-122.628/wind?38.667,-122.628,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KAPC&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.667&lon=-122.628&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.667,-122.628" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/86,146/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.667,-122.628" },
        ],
    },
    {
        id: 31,
        sitename: "stoneman park",
        category: "remote",
        lat: 38.0047,
        lng: -121.9201,
        station: "MTR",
        grid_x: 104,
        grid_y: 112,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 7,
        speedmax_ideal: 12,
        speedmin_edge: 6,
        speedmax_edge: 16,
        lightwind_ok: "no",
        dir_ideal: ["ENE", "E", "ESE", "SSW", "SW", "WSW", "NNW", "N", "NNE"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.0047&lon=-121.9201&wfo=MTR&zcode=CAZ507&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-121.9201&lat=38.0047#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.0047&lon=-121.9201&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/38.667/-122.628/wind?38.667,-122.628,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KAPC&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.0047&lon=-121.9201&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.0047,-121.9201" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/104,112/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.0047,-121.9201" },
        ],
    },
    {
        id: 32,
        sitename: "tollhouse",
        category: "remote",
        lat: 37.015,
        lng: -119.373,
        station: "HNX",
        grid_x: 69,
        grid_y: 109,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 3,
        speedmax_ideal: 8,
        speedmin_edge: 0,
        speedmax_edge: 14,
        lightwind_ok: "yes",
        dir_ideal: ["SW", "WSW", "W"],
        dir_edge: ["SSW", "SW", "WSW", "W", "WNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.015&lon=-119.373&wfo=HNX&zcode=CAZ093&gset=15&gdiff=5&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-119.373&lat=37.015#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.015&lon=-119.373&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.015/-119.373/wind?37.015,-119.371,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KFAT&state=CA" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.015&lon=-119.373&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.015,-119.373" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/HNX/69,109/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.015,-119.373" },
        ],
    },
    {
        id: 33,
        sitename: "waddel",
        category: "remote",
        lat: 37.089,
        lng: -122.274,
        station: "MTR",
        grid_x: 84,
        grid_y: 74,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 7,
        speedmax_ideal: 10,
        speedmin_edge: 7,
        speedmax_edge: 14,
        lightwind_ok: "no",
        dir_ideal: ["WSW", "W", "WNW"],
        dir_edge: ["WSW", "W", "WNW", "NW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.089&lon=-122.274&wfo=MTR&zcode=CAZ509&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.274&lat=37.089#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.089&lon=-122.274&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.015/-119.373/wind?37.015,-119.371,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KTRK&state=CA" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.089&lon=-122.274&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.089,-122.274" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/84,74/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.089,-122.274" },
        ],
    },
    {
        id: 34,
        sitename: "windy hill",
        category: "remote",
        lat: 37.364,
        lng: -122.245,
        station: "MTR",
        grid_x: 87,
        grid_y: 86,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 2,
        speedmax_ideal: 6,
        speedmin_edge: 0,
        speedmax_edge: 10,
        lightwind_ok: "no",
        dir_ideal: ["NE", "ENE", "E"],
        dir_edge: ["NNE", "NE", "ENE", "E", "ESE", "SE"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.364&lon=-122.245&wfo=MTR&zcode=CAZ511&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.245&lat=37.364#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.364&lon=-122.245&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=STO&issuedby=STO&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.364/-122.245/wind?37.364,-122.245,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://www.usairnet.com/cgi-bin/launch/code.cgi?Submit=Go&sta=KPAO&state=CA" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.364&lon=-122.245&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.364,-122.245" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/87,86/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.364,-122.245" },
            { id: 4, description: "Telegram", url: "https://wingsofrogallo.org/chat/" },
        ],
    },
    {
        id: 35,
        sitename: "berkeley cesear chavez",
        category: "kiting",
        lat: 37.871,
        lng: -122.319,
        station: "MTR",
        grid_x: 89,
        grid_y: 109,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.1144&lon=-121.7598&wfo=STO&zcode=CAZ017&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.871&lon=-122.319&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/37.871/-122.319/wind?37.871,-122.319,7,m:eHCacL2" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.871&lon=-122.319&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.871,-122.319" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/89,109/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.871,-122.319" },
        ],
    },
    {
        id: 36,
        sitename: "davis university",
        category: "kiting",
        lat: 38.5403,
        lng: -121.7585,
        station: "STO",
        grid_x: 31,
        grid_y: 68,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.5404&lon=-121.7598&wfo=STO&zcode=CAZ017&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.5404&lon=-121.7598&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/38.5404/-121.7585/wind?38.5404,-121.7598,7,m:eHCacL2" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.5404&lon=-121.7598&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.5404,-121.7598" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/89,109/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.5404,-121.7598" },
        ],
    },
    {
        id: 37,
        sitename: "mtn view shoreline",
        category: "kiting",
        lat: 37.43,
        lng: -122.076,
        station: "MTR",
        grid_x: 94,
        grid_y: 88,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.43&lon=-122.076&wfo=MTR&zcode=CAZ508&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.430&lon=-122.076&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/37.430/-122.076/wind?37.430,-122.076,7,m:eHCacL2" },
        ],
        link_current: [{ id: 4, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.43&lon=-122.076&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.430,-122.076" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/94,88/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.430,-122.076" },
        ],
    },
    {
        id: 38,
        sitename: "pacifica mussel rock",
        category: "kiting",
        lat: 37.674,
        lng: -122.495,
        station: "MTR",
        grid_x: 81,
        grid_y: 101,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.674&lon=-122.495&wfo=MTR&zcode=CAZ509&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Summary", url: "https://forecast.weather.gov/MapClick.php?lon=-122.495&lat=37.674#.WtYca9PwbUI" },
            { id: 2, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.674&lon=-122.495&unit=0&lg=english&FcstType=graphical" },
            { id: 3, description: "NWS Discussion", url: "https://forecast.weather.gov/product.php?site=MTR&issuedby=MTR&product=AFD&format=CI&version=1&glossary=1" },
            { id: 4, description: "Windy All Models", url: "https://www.windy.com/37.674/-122.495/wind?37.674,-122.495,7,m:eHCacL2" },
            { id: 5, description: "XC Skies", url: "https://www.xcskies.com/map" },
            { id: 6, description: "Aviation Weather", url: "http://usairnet.com/cgi-bin/launch/code.cgi?sta=KSFO&model=avn&state=CA&Submit=Change+Location" },
            { id: 7, description: "Tides", url: "https://tidesandcurrents.noaa.gov/noaatidepredictions.html?id=9414290" },
        ],
        link_current: [
            { id: 1, description: "Musselrockwx", url: "https://musselrockwx.com" },
            { id: 2, description: "SailFlow", url: "https://www.sailflow.com/map#37.6698,-122.4944,12,1" },
        ],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.674,-122.495" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/81,101/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.674,-122.495" },
        ],
    },
    {
        id: 39,
        sitename: "sf crissy field",
        category: "kiting",
        lat: 37.8039,
        lng: -122.4627,
        station: "MTR",
        grid_x: 83,
        grid_y: 107,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["N", "NNE", "NE", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=37.8039&lon=-122.4627&wfo=MTR&zcode=CAZ509&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=37.8039&lon=-122.4627&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/37.8039/-122.4627/wind?37.8039,-122.4627,7,m:eHCacL2" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=37.8039&lon=-122.4627&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/37.8039,-122.4627" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/MTR/83,107/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/37.8039,-122.4627" },
        ],
    },
    {
        id: 40,
        sitename: "vacaville lagoon",
        category: "kiting",
        lat: 38.333,
        lng: -122.002,
        station: "STO",
        grid_x: 21,
        grid_y: 61,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.333&lon=-122.002&wfo=STO&zcode=CAZ017&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.333&lon=-122.002&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/38.333/-122.002/wind?38.333,-122.002,7,m:eHCacL2" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.333&lon=-122.002&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.333,-122.002" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/20,60/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.333,-122.002" },
        ],
    },
    {
        id: 41,
        sitename: "vallejo river park",
        category: "kiting",
        lat: 38.1141,
        lng: -122.2708,
        station: "STO",
        grid_x: 10,
        grid_y: 53,
        hourstart: 9,
        hourend: 18,
        speedmin_ideal: 9,
        speedmax_ideal: 13,
        speedmin_edge: 7,
        speedmax_edge: 18,
        lightwind_ok: "no",
        dir_ideal: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        dir_edge: ["N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE", "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=38.1141&lon=-122.2708&wfo=STO&zcode=CAZ017&gset=18&gdiff=3&unit=0&tinfo=PY8&ahour=0&pcmd=10001110000000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=36&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=38.1141&lon=-122.2708&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/38.1141/-122.2708/wind?38.1141,-122.2708,16,m:eHCacL2" },
        ],
        link_current: [{ id: 1, description: "Weather Stations", url: "https://www.wunderground.com/wundermap?lat=38.1141&lon=-122.2708&zoom=13" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/38.1141,-122.2708" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/STO/10,53/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/38.1141,-122.2708" },
        ],
    },
    /*
    {
        id: 42,
        sitename: "kahana (temp)",
        category: "kiting",
        lat: 21.5624,
        lng: -157.8776,
        station: "HFO",
        grid_x: 153,
        grid_y: 156,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 6,
        speedmax_ideal: 13,
        speedmin_edge: 5,
        speedmax_edge: 16,
        lightwind_ok: "no",
        dir_ideal: ["NE", "ENE", "E"],
        dir_edge: ["NNE", "NE", "ENE", "E", "ESE"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=21.5624&lon=-157.8776&wfo=HFO&zcode=HIZ035&gset=18&gdiff=3&unit=0&tinfo=HN10&ahour=0&pcmd=10001110110000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=21.5624&lon=-157.8776&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/21.5624/-157.8776/wind?21.5624,-157.8776,7,m:eHCacL2" },
        ],
        link_current: [{ id: 1, description: "Wind Alert", url: "https://wx.windalert.com/search/21.455/-157.766/11" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/21.5624,-157.8776" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/HFO/153,156/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/21.5624,-157.8776" },
        ],
    },
    {
        id: 43,
        sitename: "makapuu (temp)",
        category: "kiting",
        lat: 21.3112,
        lng: -157.6626,
        station: "HFO",
        grid_x: 162,
        grid_y: 145,
        hourstart: 10,
        hourend: 18,
        speedmin_ideal: 6,
        speedmax_ideal: 13,
        speedmin_edge: 5,
        speedmax_edge: 16,
        lightwind_ok: "no",
        dir_ideal: ["NE", "ENE", "E"],
        dir_edge: ["NNE", "NE", "ENE", "E", "ESE"],
        nws_image: "https://forecast.weather.gov/meteograms/Plotter.php?lat=21.3112&lon=-157.6626&wfo=HFO&zcode=HIZ009&gset=18&gdiff=3&unit=0&tinfo=HN10&ahour=0&pcmd=10001110110000000000000000000000000000000000000000000000000&lg=en&indu=1!1!1!&dd=&bw=&hrspan=48&pqpfhr=6&psnwhr=6",
        link_forecasts: [
            { id: 1, description: "NWS Hourly", url: "https://forecast.weather.gov/MapClick.php?lat=21.3112&lon=-157.6626&unit=0&lg=english&FcstType=graphical" },
            { id: 2, description: "Windy All Models", url: "https://www.windy.com/21.3112/-157.6626/wind?21.3112,-157.6626,7,m:eHCacL2" },
        ],
        link_current: [{ id: 1,  description: "Wind Alert", url: "https://wx.windalert.com/search/21.455/-157.766/11" }],
        link_data: [
            { id: 1, description: "API", url: "https://api.weather.gov/points/21.3112,-157.6626" },
            { id: 2, description: "Data", url: "https://api.weather.gov/gridpoints/HFO/162,145/forecast/hourly" },
            { id: 3, description: "Map", url: "https://www.google.com/maps/place/21.3112,-157.6626" },
        ],
    },
    */
];
