/*
 *  Error messages at bottom of page
 *  Text will be appended here when errors occur
 */

const Bottomerrors = () => {
    return (
        <div>
            <div className="bottomspace">...</div>
        </div>
    );
};

export default Bottomerrors;
